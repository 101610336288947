




























































































import Vue from "vue";
import Component from "vue-class-component";

import { api as userApi, Getters as UserGetters } from "@/store/modules/user";
import { api as usersApi } from "@/store/modules/users";
import { api as userGroupsApi } from "@/store/modules/userGroups";
import {
  ApiV0UserAddLoginRequest,
  ApiV0UserAddLoginResponse,
} from "@auditcloud/shared/lib/schemas";
import { Action, Getter } from "vuex-class";
import { TodoActionSignature } from "@auditcloud/shared/lib/utils/type-guards";
import { validate as validName } from "@auditcloud/shared/lib/utils/sanitizer/displayName";
import { validate as validMail } from "@auditcloud/shared/lib/utils/sanitizer/email";
import {
  Group,
  assignableGroups,
} from "@auditcloud/shared/lib/utils/usermanagement/groups";

@Component({ name: "ACreateLoginUserForm" })
export default class ACreateLoginUserForm extends Vue {
  // alert data
  alertType: "success" | "error" | "warning" = "warning";
  alertText: { translationKey: string; data: { userName: string } } = {
    translationKey: "",
    data: { userName: "" },
  };
  alertShow: boolean = false;

  // login user form data
  loginUserValid: boolean = false;
  loginUserCheckbox: boolean = false;
  loginUserEmail: string = "";
  loginUserName: string = "";
  //  group data
  groupsSelected: string[] = [];
  get assignableGroups(): Group[] {
    return assignableGroups(this.getCurrentUserRoles, this.getGroups);
  }

  // role data
  rolesSelected: string[] = [];
  roles: string[] = [];

  // form validation rules
  roleRules: ((v: any) => boolean | string)[] = [
    v => !!v || "not none",
    v => (v != null && v.length > 0) || "min one",
  ];

  nameRules: ((v: any) => boolean | string)[] = [
    v => validName(v) || this.$i18n.t("common.rules.name_required").toString(),
  ];

  emailRules: ((v: any) => boolean | string)[] = [
    v => !!v || this.$i18n.t("common.rules.email_required").toString(),
    v => validMail(v) || this.$i18n.t("common.rules.email_invalid").toString(),
  ];

  // actions, getter etc...
  @Action(usersApi.actions.createLoginUser, { namespace: usersApi.namespace })
  createLoginUser!: (
    payloadData: ApiV0UserAddLoginRequest
  ) => Promise<ApiV0UserAddLoginResponse>;

  @Action(userGroupsApi.actions.clearDocuments, {
    namespace: userGroupsApi.namespace,
  })
  unlistenGroups!: TodoActionSignature;

  @Action(userGroupsApi.actions.listenOnCollection, {
    namespace: userGroupsApi.namespace,
  })
  listenForGroups!: TodoActionSignature;

  @Getter(userGroupsApi.getters.getGroupList, {
    namespace: userGroupsApi.namespace,
  })
  getGroups!: Group[];

  @Getter(userApi.getters.getCurrentUserRoles, { namespace: userApi.namespace })
  getCurrentUserRoles!: UserGetters["getCurrentUserRoles"];

  // Component Lifecycle
  created() {
    this.listenForGroups({});
  }

  beforeDestroy() {
    this.unlistenGroups();
  }

  // functions used
  async addLoginUser(): Promise<void> {
    if (this.loginUserValid) {
      // hide previous alert while request is pending
      this.updateAlertVisibility(false);

      // bundle request data and send post request
      const requestData: ApiV0UserAddLoginRequest = {
        displayName: this.loginUserName,
        email: this.loginUserEmail,
        roles: this.rolesSelected,
        applicationGroups: this.groupsSelected,
      };
      try {
        const requestResult = await this.createLoginUser(requestData);
        // success
        this.alertText = {
          translationKey: "views.user-management.creation.creation-success",
          data: { userName: requestData.displayName },
        };
        // reset form and update alert to success
        this.updateAlertType("success");
        (this.$refs.loginUserForm as Vue & { reset: () => void }).reset();
      } catch (error) {
        // Error
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }

        // change alert view accordingly to error message
        this.alertText = {
          translationKey: "views.user-management.creation.creation-error",
          data: { userName: requestData.displayName },
        };
        this.updateAlertType("error");
      }
    }
  }

  updateAlertType(type: "success" | "error" | "warning"): void {
    // change type to success and make v-alert visible
    this.alertType = type;
    this.updateAlertVisibility(true);
  }

  updateAlertVisibility(visible: boolean) {
    this.alertShow = visible;
  }
}
