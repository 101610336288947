





















import Vue from "vue";
import Component from "vue-class-component";

import ACreateLoginUserForm from "@/components/usermanagement/ACreateLoginUserForm.vue";
import ACreateExternalForm from "@/components/usermanagement/ACreateExternalForm.vue";
import { api as appApi } from "@/store/modules/app";
import { ROUTE_NAMES } from "@/routenames";
import { Action } from "vuex-class";
import { TodoActionSignature } from "@auditcloud/shared/lib/utils/type-guards";

@Component({
  name: "AUserCreationView",
  components: { ACreateLoginUserForm, ACreateExternalForm },
})
export default class AUserCreationView extends Vue {
  // array to dynamically generate/add user creation forms
  get userCreationTypes() {
    return [
      {
        type: this.$t("views.user-management.login-user"),
        component: ACreateLoginUserForm,
      },
      {
        type: this.$t("views.user-management.person-title"),
        component: ACreateExternalForm,
      },
    ];
  }

  // vuex related calls
  @Action(appApi.actions.setBreadCrumb, { namespace: appApi.namespace })
  setBreadCrumb!: TodoActionSignature;

  @Action(appApi.actions.setMenuPanel, { namespace: appApi.namespace })
  setMenuPanel!: TodoActionSignature;

  // lifecycle related
  created() {
    this.setMenuPanel("breadcrumb");
    this.updateBreadCrumbs();
  }

  // component functions
  updateBreadCrumbs(): void {
    const currentBreadCrumbs = [
      {
        text: this.$t("views.audit.home").toString(),
        to: {
          name: ROUTE_NAMES.HOME,
        },
        activeclass: "",
      },
      {
        text: this.$t("views.user-management.breadcrumb").toString(),
        to: {
          name: ROUTE_NAMES.USER_MANAGEMENT,
        },
        activeclass: "",
      },
      {
        text: this.$t("views.user-management.user-title"),

        to: { name: ROUTE_NAMES.USER_MANAGEMENT_USERS },
        activeclass: "",
      },
      {
        text: this.$t("views.user-management.user-creation"),
        to: { name: ROUTE_NAMES.USER_MANAGEMENT_CREATE_USERS },
        activeclass: "",
      },
    ];

    this.setBreadCrumb(currentBreadCrumbs);
  }
}
