









































































import Vue from "vue";
import Component from "vue-class-component";
import { Action } from "vuex-class";

import { api as userApi } from "@/store/modules/users/index";
import { ApiV0UserAddExternalRequest } from "@auditcloud/shared/lib/schemas";

import { validate as validName } from "@auditcloud/shared/lib/utils/sanitizer/displayName";
import { validate as validMail } from "@auditcloud/shared/lib/utils/sanitizer/email";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";

@Component({ name: "ACreateExternalForm" })
export default class ACreateExternalForm extends Vue {
  // alert data
  alertType: "success" | "error" | "warning" = "warning";
  alertText: {
    translationKey: string;
    data: { userName: string; email?: string };
  } = {
    translationKey: "views.user-management.person-creation-warning",
    data: { userName: "" },
  };
  alertShow: boolean = true;

  // external form data
  externalValid: boolean = false;
  externalCheckbox: boolean | null = null;
  externalEmail: string = "";
  externalName: string = "";

  creationSuccess: boolean | null = null;

  // form validation rules
  nameRules: ((v: any) => boolean | string)[] = [
    v => validName(v) || this.$i18n.t("common.rules.name_required").toString(),
  ];

  emailRules: ((v: any) => boolean | string)[] = [
    v => !!v || this.$i18n.t("common.rules.email_required").toString(),
    v => validMail(v) || this.$i18n.t("common.rules.email_invalid").toString(),
  ];

  @Action(userApi.actions.createUserInactive, { namespace: userApi.namespace })
  createUserInactive!: (payload: {
    data: ApiV0UserAddExternalRequest;
  }) => Promise<{ created: boolean; ref: IUserRef }>;

  async createExternal(): Promise<void> {
    // check if form is valid
    if (this.externalValid) {
      // hide previous alert while request is pending
      this.updateAlertVisibility(false);

      // bundle data and send post request
      const requestData: ApiV0UserAddExternalRequest = {
        displayName: this.externalName,
        email: this.externalEmail,
      };
      try {
        const requestResult = await this.createUserInactive({
          data: requestData,
        });
        console.log("requestResult", requestResult);
        if (requestResult.created) {
          // user creation successful
          this.alertText = {
            translationKey: "views.user-management.creation.creation-success",
            data: { userName: requestData.displayName },
          };
          // Reset form and update view
          this.updateAlertType("success");
          (this.$refs.externalForm as Vue & { reset: () => void }).reset();
        } else {
          // found existing User with the same email
          this.alertText = {
            translationKey: "views.user-management.creation.found-duplicate",
            data: {
              email: requestData.email,
              userName: requestResult.ref.displayName,
            },
          };
          // Reset form and update view
          this.updateAlertType("warning");
        }
      } catch (error) {
        // Error
        if (error.response) {
          /*
           * The request was made and the server responded with a
           * status code that falls out of the range of 2xx
           */
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        } else if (error.request) {
          /*
           * The request was made but no response was received, `error.request`
           * is an instance of XMLHttpRequest in the browser and an instance
           * of http.ClientRequest in Node.js
           */
          console.log(error.request);
        } else {
          // Something happened in setting up the request and triggered an Error
          console.log("Error", error.message);
        }
        // changing view accordingly
        this.alertText = {
          translationKey: "views.user-management.creation.creation-error",
          data: { userName: requestData.displayName },
        };
        this.updateAlertType("error");
      }
    }
  }

  updateAlertType(type: "success" | "error" | "warning"): void {
    // change type to success and make v-alert visible
    this.alertType = type;
    this.updateAlertVisibility(true);
  }

  updateAlertVisibility(visible: boolean) {
    this.alertShow = visible;
  }
}
